<template>
  <v-navigation-drawer app v-model="mobileDrawer">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="headline">
          <router-link to="/" tag="span" style="cursor: pointer;">
            <span class="primary--text">ΛLER</span>
            <span class="font-weight-light secondary--text">TEL/</span>
          </router-link>
        </v-list-item-title>
        <ShopSelect />
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item
        v-for="item in mobileMenuItems"
        :key="item.title"
        :to="item.link"
        link
        color="primary"
      >
        <v-list-item-icon>
          <v-badge v-if="item.title == 'alerts' && unseenAlertsCount" dot color="secondary">
            <v-icon>mdi-{{ item.icon }}</v-icon>
          </v-badge>
          <v-icon v-else>mdi-{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ $t(item.title) | capitalize }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn to="/profil" block color="primary">
          <v-icon left>mdi-account-circle</v-icon>Profil
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  components: {
    ShopSelect: () => import("@/components/Layout/NavBarShopSelect"),
  },
  data: () => ({
    mobileDrawer: false,
    mobileMenuItems: [
      { title: "home", icon: "home", link: "/" },
      { title: "alerts", icon: "bell-alert", link: "/alerts" },
      { title: "rules", icon: "pencil-ruler", link: "/rules" },
      { title: "documentation", icon: "bookshelf", link: "/documentation" },
      { title: "faq", icon: "frequently-asked-questions", link: "/faq" },
      { title: "contactus", icon: "message", link: "/contact" },
    ],
  }),
  computed: {
    unseenAlertsCount() {
      return this.$store.state.alerts.unseenAlertsCount;
    },
  },
};
</script>
